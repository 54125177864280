//
// index.tsx - Databases related functionality
//

import {
  Database,
  Databases,
  DatabaseStatus,
} from "@data-types/databases-types";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { renderAnalyzer, swrFetcher } from "@lib/client-side";
import useSWR from "swr";

// Utils

/**
 * Converts a database status number to an object containing a tooltip message and a color based on the theme.
 *
 * @param {DatabaseStatus} status - The status code of the database.
 * @param {any} theme - The theme object containing color palette information.
 * @returns {{
 *   tooltip: string;
 *   color: string;
 *   value: number;
 * }} An object representing the tooltip message, color, and status value.
 */
export function convertDatabaseStatus(
  status: DatabaseStatus,
  theme: any
): { tooltip: string; color: string; value?: number } {
  let databaseStatus: { tooltip: string; color: string; value?: number };

  switch (status) {
    case 0:
      databaseStatus = {
        tooltip:
          "File is not a valid SQLite database. If database is encrypted, provide an encryption by using the action menu.",
        color: theme.palette.neutral.lightGrey,
      };
      break;
    case 1:
      databaseStatus = {
        tooltip: "Database works as expected.",
        color: theme.palette.success.main,
      };
      break;
    case 2:
      databaseStatus = {
        tooltip: "Database is disabled.",
        color: theme.palette.neutral.lightGrey,
      };
      break;
    case 3:
      databaseStatus = {
        tooltip: "Database in maintenance mode.",
        color: theme.palette.warning.main,
      };
      break;
    case 4:
      databaseStatus = {
        tooltip:
          "Database is in error state. If database is encrypted, provide an encryption by using the action menu.",
        color: theme.palette.error.main,
      };
      break;
    default:
      databaseStatus = {
        tooltip: "UNKNOWN",
        color: theme.palette.neutral.lightGrey,
      };
      break;
  }

  // Add the status value to the returned object
  databaseStatus.value = status;
  return databaseStatus;
}

/**
 * Checks if a database name exists in the list of databases.
 *
 * @param {Database["name"]} databaseName - The name of the database to search for.
 * @param {Databases} databasesList - The list of databases, each with a `name` property.
 * @returns {boolean} Returns `true` if the database name exists, otherwise `false`.
 */
export function databaseNameExist(
  databaseName: Database["name"],
  databasesList: Databases
): boolean {
  if (databasesList) {
    return databasesList.some((database) => database.name === databaseName);
  }
  return false;
}

/**
 * Calculates the total size of a list of databases.
 *
 * @param {Databases} databases - An array of database objects, each containing a `size` property.
 * @returns {number | null} The total size of all databases, or `null` if the list is empty or undefined.
 */
export function getDatabasesTotalSize(databases: Databases): number | null {
  if (!databases) return null;

  // Calculate the sum of the `size` property from each database
  return databases.reduce((total, db) => total + db.size, 0);
}

// Hooks

/**
 * Custom hook to fetch and manage database information for a specified project ID.
 *
 * This hook retrieves database data from the API based on the provided `projectId`.
 * It also includes status indicators like loading and error states to streamline UI management.
 *
 * @param {string} projectId - The unique identifier of the project for which to fetch database information.
 * @returns {FetchHookResult<Databases>} An object containing:
 *   - `data`: The list of databases or `undefined` if no data is available.
 *   - `isLoading`: `true` if the data is currently loading; otherwise, `false`.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if the data is in the process of revalidating; otherwise, `false`.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if there is database data available.
 *   - `emptyData`: `true` if database data is available but empty.
 */
export function useGetProjectDatabases(
  projectId: string
): FetchHookResult<Databases> {
  // Fetch databases data if projectId is valid and project has nodes
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && [
        // Ensure nodes_count is positive before requesting databases
        `/api/projects/${projectId}/databases`,
        "useGetProjectDatabases",
      ],
    swrFetcher,
    {
      revalidateOnFocus: false, // Avoid revalidating data on window focus
    }
  );

  // Analyze and define indicators for data status and UI display
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    true
  );

  // Return all hook states and indicators for UI
  return {
    data: data?.value, // Contains database data if available
    isLoading: !error && !data, // True if data is still loading
    isError: error, // Holds error details if any occurred
    isValidating, // True if data is being revalidated
    showLoader, // Indicates if a loading spinner should be shown
    hasData, // True if database data is available
    emptyData, // True if data is present but empty
  };
}
